import React from 'react';
import { Link } from 'react-router-dom';
import {
  AboutContainer,
  Wrapper,
  IntroWrapper,
  AboutContent,
  ImageContainer,
  SkillsContainer,
  SkillsDescription,
  ListWrapper,
} from './AboutHeroStyle';
import ExpansionPanel from '../ExpansionPanel/ExpansionPanel';
import Star from '../Star/Star';

function AboutHero() {
  return (
    <>
      <AboutContainer>
        <Wrapper>
          <IntroWrapper>
            <h5>Nice to meet you</h5>
            <h1>
              I'm <span> Isabella Tannady</span>
            </h1>
          </IntroWrapper>
          <AboutContent>
            <ImageContainer>
              <img src='/images/selfPortrait.jpg' alt='Isabella Tannady'></img>
            </ImageContainer>
            <h6>
              I’m a creative software developer dedicated to crafting experiences that are both functional and visually
              engaging.
              {/* Currently, I contribute my experience in full-stack development
              and UX/UI design at Bitwise Industries, where I collaborate with a
              talented team to build scalable, performant product experiences. */}
              <br />
              <br />
              I enjoy developing innovative user-centered features and creating solutions to tackle complex technical
              challenges. I’m always learning and seeking new ways to enhance my skills and stay on top of modern
              building practices. Above all else, I thrive in ambitious and creative teams where we work together to
              make a positive impact.
              <br />
              <br />
              When I'm not coding or designing, you can find me hiking trails around the Bay Area, playing with my dog,
              and getting crafty with paint and clay. Check out some of my <Link to='/creations'>creations</Link>!
            </h6>
            <Star />
          </AboutContent>
          <SkillsContainer>
            <SkillsDescription>
              <h3>Skills</h3>
              <h6>
                As a developer and designer, my background includes working with databases, creating scalable
                components, and building polished front-end experiences using modern frameworks. My strong understanding
                of visual design helps me create captivating user-friendly interfaces and effectively communicate design
                ideas between cross-functional teams. I believe that design should not only look visually appealing but
                also enhance usability and leave a memorable impression.
              </h6>
            </SkillsDescription>
            <ListWrapper>
              <ExpansionPanel title='Tech Stack'>
                <p>Some of the technologies I have been working with:</p>
                <ul>
                  <li>Javascript</li>
                  <li>TypeScript</li>
                  <li>React</li>
                  <li>React Native</li>
                  <li>Angular</li>
                  <li>Node.js</li>
                  <li>MongoDB</li>
                  <li>HTML</li>
                  <li>CSS/Sass</li>
                </ul>
              </ExpansionPanel>
              <ExpansionPanel title='Tools'>
                <ul>
                  <li>Github</li>
                  <li>Visual Studio Code</li>
                  <li>AWS</li>
                  <li>Postman</li>
                  <li>Figma</li>
                  <li>Sketch</li>
                  <li>Adobe Photoshop</li>
                  <li>Adobe Illustrator</li>
                  <li>Blender</li>
                </ul>
              </ExpansionPanel>
              <ExpansionPanel title='Design'>
                <ul>
                  <li>Wireframing and prototyping</li>
                  <li>Usability testing and research</li>
                  <li>Interaction design</li>
                  <li>Information architecture</li>
                  <li>Design systems</li>
                  <li>Accessibility</li>
                  <li>Illustrations</li>
                </ul>
              </ExpansionPanel>
            </ListWrapper>
          </SkillsContainer>
        </Wrapper>
      </AboutContainer>
    </>
  );
}

export default AboutHero;
