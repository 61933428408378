import React from 'react';
import {
  ContainerWrapper,
  Container,
  HeadingWrapper,
  ImageWrapper,
  FullImageWrapper,
  ImageContainer,
  Image,
  Video,
  VideoContainer,
} from './ProjectBannerStyle';

function ProjectBanner({ project }) {
  return (
    <>
      <ContainerWrapper>
        <Container>
          {project.videoSrc && (
            <FullImageWrapper className='wContainer'>
              <VideoContainer
                className='wImg'
                projectColor={project.projectColor}
                projectShadow={project.projectShadow}
              >
                <Video
                  key={project.id}
                  className='itemImg'
                  preload='auto'
                  controlsList='nodownload noplaybackrate'
                  autoPlay
                  muted
                  loop
                  playsInline
                >
                  <source src={`${project.videoSrc}`} type='video/mp4' />
                </Video>
              </VideoContainer>
            </FullImageWrapper>
          )}
        </Container>
        {/* Design details */}
        <Container>
          {project.designHeader && (
            <HeadingWrapper>
              <h3>{project.designHeader}</h3>
              <h6>{project.designParagraph}</h6>
            </HeadingWrapper>
          )}
          {project.designImg1 && (
            <ImageWrapper className='wContainer'>
              <ImageContainer className='wImg'>
                <Image
                  className='itemImg'
                  style={{
                    backgroundImage: `url(${project.designImg1})`,
                  }}
                ></Image>
              </ImageContainer>
              <ImageContainer className='wImg'>
                <Image
                  className='itemImg'
                  style={{
                    backgroundImage: `url(${project.designImg2})`,
                  }}
                ></Image>
              </ImageContainer>
            </ImageWrapper>
          )}
          {project.designImg3 && (
            <FullImageWrapper className='wContainer'>
              <ImageContainer className='wImg'>
                <Image
                  className='itemImg'
                  style={{
                    backgroundImage: `url(${project.designImg3})`,
                  }}
                />
              </ImageContainer>
            </FullImageWrapper>
          )}
          {project.designImg4 && (
            <ImageWrapper className='wContainer'>
              <ImageContainer className='wImg'>
                <Image
                  className='itemImg'
                  style={{
                    backgroundImage: `url(${project.designImg4})`,
                  }}
                ></Image>
              </ImageContainer>
              <ImageContainer className='wImg'>
                <Image
                  className='itemImg'
                  style={{
                    backgroundImage: `url(${project.designImg5})`,
                  }}
                ></Image>
              </ImageContainer>
            </ImageWrapper>
          )}
        </Container>
        {/* Coding details */}
        <Container>
          {project.codeHeader && (
            <HeadingWrapper>
              <h3>{project.codeHeader}</h3>
              <h6>{project.codeParagraph}</h6>
            </HeadingWrapper>
          )}
          {project.codeImg1 && (
            <FullImageWrapper className='wContainer'>
              <ImageContainer className='wImg'>
                <Image
                  className='itemImg'
                  style={{
                    backgroundImage: `url(${project.codeImg1})`,
                  }}
                ></Image>
              </ImageContainer>
            </FullImageWrapper>
          )}
          {project.codeImg2 && (
            <ImageWrapper className='wContainer'>
              <ImageContainer className='wImg'>
                <Image
                  className='itemImg'
                  style={{
                    backgroundImage: `url(${project.codeImg2})`,
                  }}
                ></Image>
              </ImageContainer>
              <ImageContainer className='wImg'>
                <Image
                  className='itemImg'
                  style={{
                    backgroundImage: `url(${project.codeImg3})`,
                  }}
                ></Image>
              </ImageContainer>
            </ImageWrapper>
          )}
        </Container>
      </ContainerWrapper>
    </>
  );
}

export default ProjectBanner;
