import React, { useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useAnimationObserver } from '../../utils/animationObserver';
import { SecondaryBtn } from '../Button/SecondaryBtnStyle';
import {
  Container,
  HeadingWrapper,
  BannerWrapper,
  BannerImageContainer,
  BannerImage,
  ProjectOverview,
  Overview,
  LinkWrapper,
  TechStack,
  Timeline,
  DetailContainer,
  DetailWrapper,
  Roles,
  Categories,
  ColumnWrapper,
  RowWrapper,
} from './ProjectHeroStyle';

function ProjectHero({ project }) {
  const projectAnimation = useAnimation();
  const projectHero = useRef(null);

  useAnimationObserver(projectAnimation, projectHero);
  return (
    <>
      <motion.div ref={projectHero} initial={{ y: 50, opacity: 0 }} animate={projectAnimation}>
        <Container>
          <HeadingWrapper>
            <h5>{project.name}</h5>
            <h1>{project.header}</h1>
          </HeadingWrapper>
          <BannerWrapper className='wContainer'>
            <BannerImageContainer className='wImg'>
              <BannerImage
                className='itemImg'
                style={{
                  backgroundImage: `url(${project.heroImgSrc})`,
                }}
              ></BannerImage>
            </BannerImageContainer>
          </BannerWrapper>
          <ProjectOverview>
            <Overview>
              <RowWrapper>
                <ColumnWrapper>
                  <h3>Overview</h3>
                  <h6>{project.overview}</h6>
                </ColumnWrapper>
                <DetailContainer>
                  <DetailWrapper>
                    {project.skills && (
                      <TechStack>
                        <p className='overline'>TECH STACK</p>
                        {project.skills && project.skills.map((skill) => <li key={skill}>{skill}</li>)}
                      </TechStack>
                    )}
                    {project.roles && (
                      <Roles>
                        <p className='overline'>ROLES</p>
                        {project.roles && project.roles.map((role) => <li key={role}>{role}</li>)}
                      </Roles>
                    )}
                  </DetailWrapper>
                  <DetailWrapper>
                    <Categories>
                      <p className='overline'>CATEGORIES</p>
                      {project.categories && project.categories.map((category) => <li key={category}>{category}</li>)}
                    </Categories>
                    {project.timeline && (
                      <Timeline>
                        <p className='overline'>TIMELINE</p>
                        <p>{project.timeline}</p>
                      </Timeline>
                    )}
                  </DetailWrapper>
                </DetailContainer>
              </RowWrapper>
              <LinkWrapper>
                {project.liveLink && (
                  <a href={project.liveLink} target='_blank' rel='noopener noreferrer'>
                    <SecondaryBtn>View site</SecondaryBtn>
                  </a>
                )}
                {project.appStoreLink && (
                  <a href={project.appStoreLink} target='_blank' rel='noopener noreferrer'>
                    <SecondaryBtn>App store</SecondaryBtn>
                  </a>
                )}
                {project.githubLink && (
                  <a href={project.githubLink} target='_blank' rel='noopener noreferrer'>
                    <SecondaryBtn>Github</SecondaryBtn>
                  </a>
                )}
              </LinkWrapper>
            </Overview>
          </ProjectOverview>
        </Container>
      </motion.div>
    </>
  );
}

export default ProjectHero;
