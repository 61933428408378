import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PlayContainer, HeaderContainer, PlayWrapper, Row, ImageContainer, Image } from './PlayScrollStyle';
import { BtnWrapper, PrimaryBtn } from '../Button/PrimaryBtnStyle';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

function PlayScroll() {
  const playImages = [
    '/images/playImages/spanielWatercolor.jpg',
    '/images/playImages/angel.jpg',
    '/images/playImages/goldenWatercolor.jpg',
    '/images/playImages/calcifer.gif',
    '/images/playImages/catAcrylic.jpg',
    '/images/playImages/lofiGirl.gif',
    '/images/playImages/bernedoodleWatercolor.jpg',
    '/images/playImages/yoda.jpg',
    '/images/playImages/kikiAnimation.gif',
    '/images/playImages/terrierAcrylic.jpg',
    '/images/playImages/pomWatercolor.jpg',
    '/images/playImages/pochita.jpg',
    '/images/playImages/pitbullAcrylic.jpg',
  ];

  const secondPlayRow = playImages.slice(6, 13);

  useEffect(() => {
    let scrollTween = gsap.to('.row', {
      xPercent: -50,
      ease: 'none',
      scrollTrigger: {
        trigger: '.row',
        pin: false,
        scrub: 0.8,
        // start: "top",
        //snap: directionalSnap(1 / (sections.length - 1)),
        end: '+=3000',
      },
    });

    gsap.to('.imageContainer', {
      x: -100,
      scrollTrigger: {
        trigger: '.imageContainer',
        containerAnimation: scrollTween,
        start: 'center',
        // start: "center 80%",
        // end: "center 20%",
        scrub: true,
      },
    });

    let secondScrollTween = gsap.to('.secondRow', {
      xPercent: 50,
      ease: 'none', // <-- IMPORTANT!
      scrollTrigger: {
        trigger: '.secondRow',
        pin: false,
        scrub: 0.8,
        // start: "top",
        //snap: directionalSnap(1 / (sections.length - 1)),
        end: '+=3000',
      },
    });

    gsap.to('.secondImageContainer', {
      x: -500,
      // backgroundColor: "#1e90ff",
      // ease: "none",
      scrollTrigger: {
        trigger: '.secondImageContainer',
        containerAnimation: secondScrollTween,
        start: 'center',
        // start: "center 80%",
        // end: "center 20%",
        scrub: true,
      },
    });
  });

  return (
    <>
      <PlayContainer>
        <HeaderContainer>
          <h2>Creations</h2>
        </HeaderContainer>
        <PlayWrapper className='playWrapper'>
          <Row className='row'>
            <ImageContainer className='imageContainer'>
              {playImages.map((image, index) => (
                <Image key={index}>
                  <img src={image} alt='' />
                </Image>
              ))}
            </ImageContainer>
          </Row>
          <Row className='secondRow'>
            <ImageContainer className='secondImageContainer'>
              {secondPlayRow.map((image, index) => (
                <Image key={index}>
                  <img src={image} alt='' />
                </Image>
              ))}
            </ImageContainer>
          </Row>
        </PlayWrapper>
        <BtnWrapper>
          <Link to='/creations'>
            <PrimaryBtn>View all creations</PrimaryBtn>
          </Link>
        </BtnWrapper>
      </PlayContainer>
    </>
  );
}

export default PlayScroll;
